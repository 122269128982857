.item {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.1) 0.08%,
    rgba(153, 153, 153, 0.1) 100.08%
  );
  border-radius: 96px;
  backdrop-filter: blur(12px);
}

.item:hover {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.2) 0.08%,
    rgba(153, 153, 153, 0.2) 100.08%
  ) !important;
}
