.emailCapture {  
  flex: 1;

  div:has(input) {
    border-radius: var(--mc-scale-2);
    
    input {
      border-radius: var(--mc-scale-2);
    }
  }

  &--dark {
    div:has(input) > input {
      border: 1px solid var(--mc-color-neutral-600);
      background-color: var(--mc-color-neutral-800);
      color: var(--mc-color-neutral-90);
      &::placeholder {
        color: var(--mc-color-neutral-90);
        opacity: 0.5;
      }
    }

  }

  &--light {
    div:has(input) > input {
      background-color: var(--mc-color-neutral-90);
      color: var(--mc-color-neutral-1010);
      &::placeholder {
        color: var(--mc-color-neutral-1010);
        opacity: 0.3;
      }
    }
    
    color: var(--mc-color-neutral-600);
  }

}

.ctaButton {
  height: 48px;
}

.thankYou {
  color: white;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0.1) 0.08%,
    rgba(153, 153, 153, 0.1) 100.08%
  );
  padding: var(--mc-scale-6);
  border-radius: var(--mc-scale-4);
}

.termsCheckbox {
  label {
    line-height: 17.4px;
    text-align: left;
  }
}
