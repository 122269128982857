@import '~@mc/design-system/src/styles/base/base';

.content {
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  overflow-x: auto;
  overflow-scrolling: touch;
  color-scheme: dark;
  scroll-snap-stop: normal;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    flex-shrink: 0;
  }

  &.scrollSnapAlignStart {
    > * {
      scroll-snap-align: start;
    }
  }

  &.scrollSnapAlignCenter {
    > * {
      scroll-snap-align: center;
    }
  }

  &.scrollSnapAlignEnd {
    > * {
      scroll-snap-align: end;
    }
  }

  &.overflow {
    --edge: rgba(0, 0, 0, 0.2);
    --inner: rgba(0, 0, 0, 1);
    --feather: calc(var(--mc-scale-12) * 2);

    margin: 0 var(--mc-overflow-outer-margin);
    padding: 0 var(--mc-overflow-inner-padding);
    scroll-padding: 0 var(--mc-overflow-inner-padding);

    // -100% is a little hack so that the next lines can be negative and not default to 0%
    mask-image: linear-gradient(
      to right,
      var(--edge) -100%,
      var(--edge) calc(var(--mc-overflow-inner-padding) - var(--feather)),
      var(--inner) var(--mc-overflow-inner-padding),
      var(--inner) calc(100% - var(--mc-overflow-inner-padding)),
      var(--edge) calc(100% - var(--mc-overflow-inner-padding) + var(--feather)),
      var(--edge) 100%
    );
  }
}

// Applicable to all arrows
.arrow {
  opacity: 1;
  transition: opacity 250ms ease;

  &.disabled {
    opacity: 0.4;
  }
}

.pages {
  text-align: center;
}

.page {
  display: inline-block;
  margin: 0 3px;

  width: 4px;
  height: 4px;
  background: var(--mc-theme-carousel-dot);
  border-radius: 4px;
  outline: 0;
  transition: background 250ms ease, width 200ms ease-out;
  pointer-events: none;

  &:focus {
    background: var(--mc-theme-carousel-dot-active);
  }

  &.active {
    background: var(--mc-theme-carousel-dot-active);
    width: 16px;
  }
}

// Applicable to classic arrows only
.arrowsClassic {
  position: relative;

  .arrow {
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  .prev {
    left: 0;
    transform: translate(-50%, -50%);
  }

  .next {
    right: 0;
    transform: translate(50%, -50%);
  }
}
