.header {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -32px;
    left: calc(50% - 48px);
    height: 4px;
    width: 96px;
    border-radius: 32px;
    background: rgba(255, 255, 255, 0.15);
  }
}
