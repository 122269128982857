.badge {
  left: var(--mc-scale-3);
  position: absolute;
  top: var(--mc-scale-3);
  z-index: 1;
}

.textContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.4) 18.81%,
    #000000 100%
  );
}

.suggestion {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(153, 153, 153, 0.1) 100%
  );
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--mc-scale-3) var(--mc-scale-7);
  backdrop-filter: blur(12px);

  &:disabled {
    opacity: 1;
  }

  p.noBlur {
    backdrop-filter: none;
  }
}
