@import '~@mc/design-system/src/styles/base/base';

.mc-video {
  position: relative;
  background: $mc-color-dark;
  overflow: hidden;
  user-select: none;

  &--windowed & {
    &--16x9 {
      @include aspect-ratio(16, 9);
    }

    &--2x3 {
      @include aspect-ratio(2, 3);
    }

    &--1x1 {
      @include aspect-ratio(1, 1);
    }

    &--9x16 {
      @include aspect-ratio(9, 16);
    }
  }

  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    z-index: 0;

    .mc-video--state-idle &,
    .mc-video--state-loading & {
      object-fit: cover;
    }

    .mc-video--fit-cover & {
      object-fit: cover;
    }
    .mc-video--fit-fill & {
      object-fit: fill;
    }
  }

  &__controls {
    position: absolute;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    pointer-events: all;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba($mc-color-dark, 0.4);
    transform: translate(0, 150%);
    transition: transform ease 400ms;

    .mc-video--interacting &,
    &:hover {
      transform: translate(0, 0);
    }
  }

  &__scrubber {
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 100%;
    height: var(--mc-scale-5);
    z-index: 1;
    transform: translateY(calc(50% - (var(--mc-scale-1) / 2)));

    .mc-slider {
      position: absolute;
      left: 0;
      width: 100%;

      &__bar,
      &__buffer,
      &__fill {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__volume {
    &-slider {
      position: relative;

      display: none;

      @media (min-width: $mc-bp-sm) {
        display: flex;
      }

      .mc-slider {
        width: 0;
        opacity: 0;
        transition:
          opacity ease 200ms,
          width ease 200ms;

        .mc-video__volume:hover &,
        &:focus {
          width: 60px;
          opacity: 1;
        }
      }
    }
  }

  &__time {
    margin: 0 var(--mc-scale-3);
    display: flex;
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  // not super important for desktop, but this allows
  // mobile to go fullscreen and maintain custom controls
  &--fullscreen {
    position: fixed;
    inset: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 1030;
  }

  .mc-video--fullscreen &:not(.mc-video--interacting),
  .mc-video--fullscreen &:not(:hover) {
    cursor: none;
  }

  @supports not (-webkit-touch-callout: none) {
    video::-webkit-media-text-track-container,
    video::-webkit-media-text-track-display {
      display: none !important;
    }
  }

  /* hide the custom text tracks on iOS Safari */
  @supports (-webkit-touch-callout: none) {
    .mc-video--text-track {
      display: none !important;
    }
  }
}
