@import '~@mc/design-system/src/styles/base/base';

.glowEffect {
  top: 40%;
  left: 50%;
}

.glowEffect::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70vw;
  max-width: 950px;
  aspect-ratio: 1.55;
  border-radius: 50%;
  opacity: 0.6;
  background: radial-gradient(
    52.2% 52.2% at 50% 76.2%,
    #cfd7eb 6%,
    #8b9dc7 31%,
    #364362 80%
  );
  filter: blur(200px);

  @media (max-width: $mc-bp-md) {
    width: 110vw;
  }
}
