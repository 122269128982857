@import '@mc/design-system/src/styles/base/base';

.tabsContainer {
  position: relative;
  button {
    flex-basis: 50%;
  }
}

.tab {
  color: var(--mc-color-text-light);
  background-color: transparent;
  border: 1px solid #F9F2E8;
}

.tabActive {
  color: var(--mc-color-neutral-1000);
  background-color: var(--mc-color-neutral-90);
}

.tabPanels {
  @media (max-width: $mc-bp-sm) {
    overflow: scroll;
    width: 100dvw;
    padding: 0 var(--mc-container-padding);
    scroll-padding: 0 var(--mc-container-padding);
    scroll-snap-stop: normal;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

}

.tabPanel {
  scroll-snap-align: center;

  h3 {
    font-size: 3.6rem;
    line-height: 100%;
    letter-spacing: -1.8px;
  }
}

.listItem {
  &, span {
    color: var(--mc-color-text-light)!important;
    border: 1px solid #F9F2E8;
  }

  line-height: 100%;
  text-wrap: nowrap;
  letter-spacing: -1px;

  width: 26.6rem;

  @media (min-width: $mc-bp-lg) {
    width: 30rem;
  }

}

.listItemActive {
  color: var(--mc-color-neutral-1000)!important;
  background-color: var(--mc-color-neutral-90);
  border: 1px solid var(--mc-color-neutral-90);
}
