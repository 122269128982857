@import '~@mc/design-system/src/styles/base/base';

.stickyContainer {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(12px);
  padding: var(--mc-scale-4) var(--mc-scale-8);

  @media (min-width: $mc-bp-md) {
    padding: var(--mc-scale-4) var(--mc-scale-10);
  }
}

.ctaButton {
  padding: var(--mc-scale-4) var(--mc-scale-8);
  margin-left: var(--mc-scale-6);
  border-radius: var(--mc-scale-12);
}
