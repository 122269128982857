@import '~@mc/design-system/src/styles/base/base';

.background {
  background: var(--mc-color-neutral-900);

  @media (min-width: $mc-bp-sm) {
    max-width: 583px;
    position: relative;
    border-bottom-left-radius: var(--mc-scale-4);
    border-bottom-right-radius: var(--mc-scale-4);
  }
}

.emailCaptureForm {
  input {
    border-radius: 4px !important;
    @media (max-width: $mc-bp-md) {
      height: 33px;
      font-size: 12px;
    }
  }
  button {
    @media (max-width: $mc-bp-md) {
      height: 33px;
      font-size: 12px;
    }
  }
}

.terms {
  color: #9ea0a9;
}
