// Fonts
$mc-font-default: 'Sohne', 'Helvetica', 'Arial', sans-serif !default;
$mc-font-brand: 'Sohne Schmal', 'Impact-fallback', 'Helvetica', 'Arial',
  sans-serif !default;

// Colors - legacy
// Todo: Post new color launch, find and remove all
// references from the code base

$mc-color-light: #fff !default;
$mc-color-dark: #000 !default;

$mc-color-gray-100: #191c21 !default;
$mc-color-gray-200: #272c33 !default;
$mc-color-gray-300: #31333b !default;
$mc-color-gray-400: #596170 !default;
$mc-color-gray-500: #949aa4 !default;
$mc-color-gray-600: #dbdde1 !default;
$mc-color-gray-700: #f3f3f3 !default;
$mc-color-gray-800: #f8f8f8 !default;

$mc-color-primary: #e32652 !default;
$mc-color-primary-hover: lighten($mc-color-primary, 5%) !default;
$mc-color-primary-active: darken($mc-color-primary, 5%) !default;

$mc-color-secondary: $mc-color-gray-200 !default;
$mc-color-secondary-hover: $mc-color-gray-300 !default;
$mc-color-secondary-active: $mc-color-gray-200 !default;

$mc-color-tertiary: $mc-color-gray-400 !default;
$mc-color-tertiary-hover: $mc-color-gray-300 !default;
$mc-color-tertiary-active: $mc-color-gray-200 !default;

$mc-color-background: $mc-color-dark !default;
$mc-color-background-invert: $mc-color-light !default;
$mc-color-text: $mc-color-light !default;
$mc-color-text-invert: #8ca1c1 !default;

$mc-color-error: #e00000 !default;
$mc-color-success: #3ebb70 !default;
$mc-color-reward: #f1bd5b !default;
$mc-color-reward-start: #f8d05e !default;
$mc-color-reward-end: #e5a754 !default;
$mc-color-highlight: $mc-color-reward !default;

// Camp
$mc-color-camp-light-yellow: #fffff2;
$mc-color-camp-dark-yellow: #f9f8e9;
$mc-color-camp-salmon: #ee1c4e;

// Specific colors for enroll page
$mc-color-camp-dark-green: #246b40;

// Specific colors for submission hyperlinks
$mc-color-camp-link: #1a749e;
$mc-color-camp-link-hover: #145a7b;
$mc-color-camp-link-visited: rgb(85, 26, 139);

// My Progress
$progress-text-color: #d1e8bc;

// External brands
$mc-color-facebook: #3c5a99 !default;
$mc-color-google: #4285f4 !default;
$mc-color-linkedin: #0e76a8 !default;
$mc-color-messenger: #0078ff !default;
$mc-color-twitter: #2ba2ef !default;
$mc-color-paypal: #ffc439 !default;
$mc-color-pinterest: #bd081c !default;
$mc-color-apple: #000 !default;

// The yiq lightness value that determines when the
// lightness of color changes from "dark" to "light".
// Acceptable values are between 0 and 255.

$yiq-contrasted-threshold: 150 !default;
$yiq-text-dark: #777 !default;
$yiq-text-light: #fff !default;

// Animations
$mc-default-ease: cubic-bezier(0.25, 0.1, 0.25, 1) !default;

// Z-indexes
$mc-zindex-dropdown-backdrop: 900 !default;
$mc-zindex-dropdown: 1000 !default;
$mc-zindex-sticky: 1000 !default;
$mc-zindex-fixed: 1030 !default;
$mc-zindex-modal-backdrop: 1040 !default;
$mc-zindex-modal: 1050 !default;
$mc-zindex-popover: 1060 !default;
$mc-zindex-tooltip: 1070 !default;
$mc-zindex-toast: 1080 !default;

// Step measurements
$mc-step-base: 10 !default;
$mc-step-scale: 4 !default;
$mc-step-group: 7 !default;
$mc-step-min-resize: 4 !default;
$mc-step-decay-sm: 3 !default;
$mc-step-decay-md: 2 !default;
$mc-step-decay-lg: 0 !default;

// Custom bootstrap grid overrides
$enable-grid-classes: true !default;
$grid-columns: 12 !default;
$grid-gutter-width: 16px !default;

// Breakpoints
$mc-bp-xs: 0 !default;
$mc-bp-sm: 576px !default;
$mc-bp-md: 768px !default;
$mc-bp-lg: 960px !default;
$mc-bp-xl: 1200px !default;
// NOTE: If we change these breakpoint values, we
// also need to update them for ResponsiveHandler.

$grid-breakpoints: (
  xs: $mc-bp-xs,
  sm: $mc-bp-sm,
  md: $mc-bp-md,
  lg: $mc-bp-lg,
  xl: $mc-bp-xl,
) !default;

// We override bootstrap's default padding on containers.
//
// The only container that needs to be set is at the xl breakpoint,
// the rest are set to 100% of the window.
$container-max-width: $mc-bp-xl;
$container-padding: $grid-gutter-width * 2;
$xl-container-max-width: $container-max-width + $container-padding * 2;
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: $xl-container-max-width,
) !default;

// Buttons
$btn-kinds: (
  'primary',
  'secondary',
  'tertiary',
  'success',
  'link',
  'linked-in',
  'messenger',
  'google',
  'facebook',
  'twitter',
  'pinterest',
  'paypal',
  'applepay'
) !default;

// Forms
$input-padding: var(--mc-scale-3) var(--mc-scale-4) !default;
$input-padding-large: var(--mc-scale-4) var(--mc-scale-5) !default;
$input-padding-small: var(--mc-scale-2) var(--mc-scale-3) !default;

// Spacing
$scale-begin: 0 !default;
$scale-end: 16 !default;

// Rounded to perfection
$default-radius: var(--mc-scale-1) !default;

// Transition
$transition-function-default: ease;
$transition-duration-default: 0.5s;

// Box Shadow
$box-shadow-banner: var(--mc-theme-box-shadow-light);
